import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// Import the sections
import Branding from "../components/branding"
import Partnering from "../components/partnering"
import Hire from "../components/hire"
import Brands from "../components/brands"
import Services from "../components/services"
import Steps from "../components/steps"
import Form from "../components/form"

// Import Homepage Query
import { homepageQuery } from "../utils/home-page-query"

// Import Types
import {
  HomepageData,
  BrandingData,
  PartneringData,
  HireData,
  BrandsData,
  ServicesData,
  StepsData,
  FormData,
  SectionTypes,
} from "../utils/types"

const Pages: React.FC<PageProps<HomepageData>> = ({
  data: {
    contentfulHomePageLayout: { seoTitle, metaDescription, layout },
  },
}) => {
  const loadSection = (section: SectionTypes) => {
    switch (section.internal.type) {
      case "ContentfulBranding":
        return <Branding key={section.id} {...(section as BrandingData)} />
      case "ContentfulPartnering":
        return <Partnering key={section.id} {...(section as PartneringData)} />
      case "ContentfulHire":
        return <Hire key={section.id} {...(section as HireData)} />
      case "ContentfulBrands":
        return <Brands key={section.id} {...(section as BrandsData)} />
      case "ContentfulServices":
        return <Services key={section.id} {...(section as ServicesData)} />
      case "ContentfulSteps":
        return <Steps key={section.id} {...(section as StepsData)} />
      default:
        return <Form key={section.id} {...(section as FormData)} />
    }
  }

  return (
    <Layout>
      <Seo title={seoTitle} description={metaDescription.metaDescription} />
      {layout.map(section => loadSection(section))}
    </Layout>
  )
}

export default Pages

export const query = graphql`
  query HomepageQuery($contentful_id: String!) {
    contentfulHomePageLayout(contentful_id: { eq: $contentful_id }) {
      seoTitle
      metaDescription {
        metaDescription
      }
      layout {
        ... on ContentfulBrands {
          id
          headline
          copy {
            childMarkdownRemark {
              html
            }
          }
          brands {
            name
            logo {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 250
              )
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulForm {
          id
          headline
          copy {
            childMarkdownRemark {
              html
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulHire {
          id
          reasonOne {
            childMarkdownRemark {
              html
            }
          }
          reasonThree {
            childMarkdownRemark {
              html
            }
          }
          reasonTwo {
            childMarkdownRemark {
              html
            }
          }
          copy {
            childMarkdownRemark {
              html
            }
          }
          background {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          headline
          internal {
            type
          }
        }
        ... on ContentfulPartnering {
          id
          avoid
          headline
          subheader
          copy {
            childMarkdownRemark {
              html
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulServices {
          id
          headline
          subheader
          services {
            image {
              id
              image
            }
            id
            copy {
              childMarkdownRemark {
                html
              }
            }
            title
          }
          copy {
            childMarkdownRemark {
              html
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulSteps {
          id
          headline
          subheader
          steps {
            id
            copy {
              childMarkdownRemark {
                html
              }
            }
            image {
              id
              image
            }
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulBranding {
          id
          background {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          headline
          tagline
          internal {
            type
          }
        }
      }
    }
  }
`
