import * as React from "react"

import { PartneringData } from "../utils/types"
import ScheduleACall from "./schedule-a-call"

import Container from "./container"

import IconArrow from "../svgs/bullet-x-orange.svg"

const Avoid: React.FC<{ item: string }> = ({ item }) => (
  <div className="grid grid-cols-avoid items-center">
    <IconArrow className="h-6 self-start mt-1.5" />
    <span className="text-nmg-blue font-headline text-3xl">{item}</span>
  </div>
)

const Partnering: React.FC<PartneringData> = ({
  headline,
  subheader,
  avoid,
  copy,
}) => {
  return (
    <Container>
      <h2 className="font-headlineCondensed text-6xl leading-header text-center max-w-prose">
        {headline}
      </h2>
      <h5 className="font-headline text-2xl text-center md:text-3xl md:-mt-7 text-gray-600">
        {subheader}
      </h5>
      <div className="mx-auto grid grid-cols-1 gap-3 md:gap-x-24 md:grid-cols-2">
        {avoid.map(item => (
          <Avoid key={item} item={item} />
        ))}
      </div>
      <div
        className="text-lg md:text-2xl py-3 md:max-w-3xl md:mx-auto"
        dangerouslySetInnerHTML={{
          __html: copy.childMarkdownRemark.html,
        }}
      />
      <ScheduleACall header={false} />
    </Container>
  )
}

export default Partnering
