import * as React from "react"

import { HireData, MarkdownData } from "../utils/types"

import Check from "../svgs/bullet-check-white.svg"
import Container from "./container"
import Curve from "./curve"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"

const Reason: React.FC<MarkdownData> = ({ childMarkdownRemark: { html } }) => {
  return (
    <div className="flex-1 flex flex-col justify-between bg-white rounded-md my-3">
      <div className="-mb-14 w-14 overflow-hidden rounded-tl-md">
        <div className="h-20 bg-nmg-green rotate-45 transform origin-top-right flex flex-col justify-center items-end">
          <Check className="h-5 -rotate-45 transform" />
        </div>
      </div>
      <div
        className="font-headlineCondensed px-6 py-3 text-2xl md:text-3xl"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <div className="-mt-16 w-14 overflow-hidden corner-end rounded-br-md">
        <div className="h-20 bg-nmg-green rotate-45 transform origin-bottom-left"></div>
      </div>
    </div>
  )
}

const Hire: React.FC<HireData> = ({
  headline,
  copy,
  reasonOne,
  reasonTwo,
  reasonThree,
  background,
}) => {
  const image = getImage(background.gatsbyImageData)

  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage Tag="section" className="bg-cover bg-center" {...bgImage}>
      <Curve top={true} fill={"fill-green"} />
      <Container section={false}>
        <h3 className="font-headline font-bold text-white text-5xl leading-header text-center uppercase filter drop-shadow-nmg">
          {headline}
        </h3>
        <div
          className="text-lg md:text-2xl text-white md:max-w-3xl md:mx-auto"
          dangerouslySetInnerHTML={{
            __html: copy.childMarkdownRemark.html,
          }}
        />
        <div className="flex flex-col md:flex-row gap-6 md:gap-12">
          <Reason {...reasonOne} />
          <Reason {...reasonTwo} />
          <Reason {...reasonThree} />
        </div>
      </Container>
      <Curve fill={"fill-green"} />
    </BackgroundImage>
  )
}

export default Hire
