import * as React from "react"

import { StepsData } from "../utils/types"
import Container from "./container"
import ScheduleACall from "./schedule-a-call"
import SVG from "./svg"

const Steps: React.FC<StepsData> = ({ headline, subheader, steps }) => {
  return (
    <Container>
      <h3 className="font-headline font-semibold text-nmg-blue text-5xl md:text-7xl text-center uppercase">
        {headline}
      </h3>
      <h5 className="font-headline font-bold text-4xl md:text-5xl text-center -mt-6">
        {subheader}
      </h5>
      <div className="flex flex-col md:flex-row gap-8 md:gap-x-28 items-center py-6">
        {steps.map(step => (
          <SVG
            key={step.id}
            copyClass="text-lg md:text-2xl"
            className="flex flex-col items-center text-center"
            headlineClass="font-headline font-bold text-nmg-blue text-3xl mb-2"
            svgClass="h-24"
            {...step}
          />
        ))}
      </div>
      <ScheduleACall header={false} />
    </Container>
  )
}

export default Steps
