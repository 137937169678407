import * as React from "react"
import * as Yup from "yup"
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik"

interface FormValues {
  name: string
  phone: string
  email: string
  help: string
}

interface Label {
  name: string
  label?: string
  placeHolder: string
}

const InputWrapper: React.FC<{ classes?: string[] }> = ({
  classes,
  children,
}) => {
  const defaultClasses: string[] = ["flex", "flex-col"]

  const className = defaultClasses.concat(classes).join(" ")

  return <div className={className}>{children}</div>
}

const ContactForm: React.FC<{}> = () => {
  const labels: Label[] = [
    {
      name: "name",
      placeHolder: "Name",
    },
    {
      name: "email",
      placeHolder: "Email",
    },
    {
      name: "phone",
      placeHolder: "Phone",
    },
  ]

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "That name seems too short?")
      .max(50, "That name seems too long?")
      .required("First and Last Name Required."),
    email: Yup.string()
      .email("That email, there is something wrong.")
      .required("A valid email is required."),
    phone: Yup.string()
      .matches(
        phoneRegExp,
        "Check you phone number, looks like there is something wrong"
      )
      .required("A valid phone number is required."),
    help: Yup.string()
      .min(4, "Please elaborate we need more information.")
      .required("Let us know how we can help you."),
  })

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    help: "",
  }

  const [completed, setCompleted] = React.useState(false)

  const onSubmit = async (values: FormValues) => {
    const response = await fetch(
      "https://dbqb68rija.execute-api.us-east-1.amazonaws.com/dev/nmg",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )

    const { data, errors } = await response.json()

    if (response.ok) {
      setCompleted(true)
    } else {
      console.log(errors)
    }
  }

  if (completed) {
    return (
      <p className="font-headline text-3xl md:text-5xl text-center">
        Thank you! Your request has been submitted.
      </p>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form className="flex flex-col md:w-6/12 md:mx-auto mb-6">
        {labels.map(({ name, label, placeHolder }) => (
          <InputWrapper key={name}>
            {label && (
              <label className="text-sm" htmlFor={name}>
                {label}
              </label>
            )}
            <Field
              className="px-4 my-3 rounded"
              type={name === "email" ? "email" : "text"}
              name={name}
              placeholder={placeHolder}
            />
            <ErrorMessage name={name} />
          </InputWrapper>
        ))}

        <InputWrapper>
          <Field
            className="px-4 my-3 rounded"
            rows="6"
            name="help"
            as="textarea"
            placeholder="How can we help you?"
          />
          <ErrorMessage name="help" />
        </InputWrapper>

        <InputWrapper classes={["col-span-2 flex items-end"]}>
          <button
            className="rounded py-4 px-10 text-2xl font-bold bg-action text-white hover:scale-105"
            type="submit"
          >
            Submit
          </button>
        </InputWrapper>
      </Form>
    </Formik>
  )
}

export default ContactForm
