import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { BrandsData, BrandData } from "../utils/types"

import Container from "./container"

const Brand: React.FC<BrandData> = ({ name, logo }) => (
  <div className="bg-gray-200 w-brand h-brand sm:w-auto sm:h-auto shadow-nmg p-4 flex items-center">
    <GatsbyImage
      imgClassName="object-contain w-full"
      alt={`${name} Logo`}
      image={logo.gatsbyImageData}
    />
  </div>
)

const Brands: React.FC<BrandsData> = ({ headline, copy, brands }) => {
  return (
    <Container>
      <h3 className="font-headline text-5xl md:text-7xl leading-header text-center">
        {headline}
      </h3>

      <div
        className="text-lg md:text-2xl md:max-w-3xl md:mx-auto space-y-7"
        dangerouslySetInnerHTML={{
          __html: copy.childMarkdownRemark.html,
        }}
      />

      <div className="flex flex-col sm:flex-row gap-7 sm:gap-6 md:gap-10 md:mx-auto items-center sm:items-stretch mb-6">
        {brands.map(brand => (
          <Brand key={brand.name} {...brand} />
        ))}
      </div>

    </Container>
  )
}

export default Brands
