import * as React from "react"

import { FormData } from "../templates/pages"
import ContactForm from "./contact-form"
import Container from "./container"

import Curve from "./curve"

const Form: React.FC<FormData> = ({ headline, copy }) => (
  <div className="bg-nmg-grey" id="schedule-a-call">
    <Curve top={true} fill="fill-grey" />
    <Container>
      <h5 className="font-headline text-5xl md:text-7xl leading-header text-center">
        {headline}
      </h5>
      <div
        className="text-lg md:text-xl md:max-w-3xl md:mx-auto"
        dangerouslySetInnerHTML={{
          __html: copy.childMarkdownRemark.html,
        }}
      />
      <ContactForm />
    </Container>
  </div>
)

export default Form
