import * as React from "react"

import { ServicesData } from "../utils/types"
import ScheduleACall from "./schedule-a-call"
import SVG from "./svg"
import Container from "./container"

import Curve from "./curve"

const Services: React.FC<ServicesData> = ({
  headline,
  subheader,
  services,
  copy,
}) => {
  return (
    <div className="bg-nmg-red">
      <Curve top={true} fill="fill-nmg-red" />
      <Container>
        <h3 className="font-headlineCondensed font-bold text-white text-5xl md:text-7xl leading-header text-center uppercase filter drop-shadow-nmg">
          {headline}
        </h3>
        <div
          className="text-lg md:text-xl text-white md:max-w-3xl md:mx-auto"
          dangerouslySetInnerHTML={{
            __html: copy.childMarkdownRemark.html,
          }}
        />
        <h5 className="font-headlineCondensed font-bold text-white text-4xl md:text-5xl text-center filter drop-shadow-nmg">
          {subheader}
        </h5>
        <div className="flex flex-col flex-wrap md:flex-row md:gap-8 justify-center">
          {services.map(service => (
            <SVG
              key={service.id}
              className="flex flex-col items-center md:w-3/12"
              headlineClass="font-headline font-bold text-white text-3xl text-center"
              svgClass="h-40"
              {...service}
            />
          ))}
        </div>
        <ScheduleACall header={false} reverse={true} />
      </Container>
      <Curve fill="fill-nmg-red" />
    </div>
  )
}

export default Services
