import * as React from "react"

import { BrandingData } from "../utils/types"
import ScheduleACall from "./schedule-a-call"
import Curve from "./curve"
import Container from "./container"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"

import Plus from "../svgs/bullet-plus-blue.svg"

const BrandingTop: React.FC<BrandingData> = ({
  headline,
  tagline,
  background,
}) => {
  const image = getImage(background.gatsbyImageData)

  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage Tag="section" className="bg-cover bg-center" {...bgImage}>
      <Container section={false}>
        <h1 className="font-headline font-bold text-white text-5xl md:text-7xl md:w-9/12 md:mx-auto md:mt-7 leading-header text-center filter drop-shadow-nmg">
          {headline}
        </h1>
        <div className="mx-auto w-80 md:w-auto md:max-w-5xl grid grid-cols-1 md:grid-cols-plus divide-y md:divide-none divide-nmg-blue justify-items-center border-t md:border-0 border-b border-nmg-blue items-center">
          {tagline.map((tag, i) => (
            <React.Fragment key={tag}>
              {i > 0 && <Plus className="h-9 hidden md:block" />}
              <span className="py-5 w-full font-headlineCondensed font-bold text-white text-4xl md:text-5xl text-center md:max-w-xs filter drop-shadow-nmg">
                {tag}
              </span>
            </React.Fragment>
          ))}
        </div>
        <ScheduleACall header={false} />
      </Container>
      <Curve fill="fill-blue" />
    </BackgroundImage>
  )
}

export default BrandingTop
