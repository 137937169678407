import * as React from "react"

import SectionBtm from "../svgs/section-btm.svg"
import SectionTop from "../svgs/section-top.svg"

type CurveProps = {
  top?: boolean
  fill: string
}

const Curve: React.FC<CurveProps> = ({ top = false, fill }) => (
  <div className="z-10">
    {top ? (
      <SectionTop className={`w-full ${fill}`} />
    ) : (
      <SectionBtm className={`w-full ${fill}`} />
    )}
  </div>
)

export default Curve
